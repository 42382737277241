import Vue from 'vue'
import VueRouter from 'vue-router'
import main from './main'
import { Message } from 'element-ui';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'view',
    redirect: '/Home', // 若不进行重定向则展示HomeView页面--只有头部和底部, 重定向后默认展示Home页面
    component: () => import('@/views/components/HomeView.vue'),
    children: [
      ...main
    ]
  },
  {
    path: '/Daodu',
    name: 'Daodu',
    meta: {
      title: '导读'
    },
    component: () => import('@/views/daodu.vue')
  },
  {
    path: '/Gx',
    name: 'Gx',
    meta: {
      title: '导读'
    },
    component: () => import('@/views/gx.vue')
  },
  {
    path: '/OGongxu',
    name: 'OGongxu',
    meta: {
      title: '公需'
    },
    component: () => import('@/views/other/gongxu.vue')
  },
  {
    path: '/Zhuanye',
    name: 'Zhuanye',
    meta: {
      title: '公需'
    },
    component: () => import('@/views/other/zhuanye.vue')
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    meta: {
      title: '隐私协议'
    },
    component: () => import('@/views/privacy.vue')
  },
]

const router = new VueRouter({
  routes
})

// 数组中的路径为开发页面路径--无需登录
const arr = [
  '/',
  '/Home',
  '/Tixi',
  '/News',
  '/ConsultList',
  '/ConsultDetail',
  '/Teacher',
  '/Daodu',
  '/Gx',
  '/Zhuanye',
  '/OGongxu',
  '/IssueList',
  '/IssueInfo',
  '/xfList',
  '/Privacy'
]

const root = [
  '/Kecheng',
  '/Xuanke',
  '/Xuankao',
  '/Xuexi',
  '/Exam',
  '/Kaoshi',
  '/Lianxi',
  '/Peixun',
  '/Gouke',
]

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token') || ''
  if (arr.indexOf(to.path) == -1) {
    // 当前往的页面不再arr数组中是，即需要登录权限的页面
    if (token) {
      let is_ok = localStorage.getItem('is_ok') || '1'
      if (is_ok == 0 && root.indexOf(to.path) != -1) {
        Message.warning("暂无专业科目学习权限")
        return
      } else {
        next()
      }
    } else {
      next('/Home')
    }
  } else {
    next()
  }
})

export default router
