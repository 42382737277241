export default [
  {
    path: '/Home',
    name: 'Home',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/main.vue')
  },
  {
    path: '/Page',
    name: 'Page',
    meta: {
      keepAlive: true,
    },
    component: () => import('@/views/components/SideView.vue'),
    children: [
      {
        path: '/Kecheng',
        name: 'Kecheng',
        meta: {
          title: '课程报名'
        },
        component: () => import('@/views/kecheng.vue')
      },
      {
        path: '/Xuanke',
        name: 'Xuanke',
        meta: {
          title: '专业课程'
        },
        component: () => import('@/views/xuanke.vue')
      },
      {
        path: '/Xuexi',
        name: 'Xuexi',
        meta: {
          title: '专业课程'
        },
        component: () => import('@/views/xuexi.vue')
      },
      {
        path: '/Exam',
        name: 'Exam',
        meta: {
          title: '专业课程'
        },
        component: () => import('@/views/exam.vue')
      },
      {
        path: '/Gongxu',
        name: 'Gongxu',
        meta: {
          title: '公需课程'
        },
        component: () => import('@/views/gongxu.vue')
      },
      {
        path: '/Maike',
        name: 'Maike',
        meta: {
          title: '公需课程'
        },
        component: () => import('@/views/maike.vue')
      },
      {
        path: '/Xuankao',
        name: 'Xuankao',
        meta: {
          title: '药师考试'
        },
        component: () => import('@/views/xuankao.vue')
      },
      {
        path: '/Kaoshi',
        name: 'Kaoshi',
        meta: {
          title: '正式考试'
        },
        component: () => import('@/views/kaoshi.vue')
      },
      {
        path: '/Lianxi',
        name: 'Lianxi',
        meta: {
          title: '随堂练习'
        },
        component: () => import('@/views/lianxi.vue')
      },
      {
        path: '/Fapiao',
        name: 'Fapiao',
        meta: {
          keepAlive: true,
          title: '申请发票'
        },
        component: () => import('@/views/fapiao.vue')
      },
      {
        path: '/FaPiaoArr',
        name: 'FaPiaoArr',
        meta: {
          title: '申请发票'
        },
        component: () => import('@/views/components/fapiao/fapiaoarr.vue')
      },
      {
        path: '/KaiFaPiao',
        name: 'KaiFaPiao',
        meta: {
          title: '申请发票'
        },
        component: () => import('@/views/components/fapiao/kaifapiao.vue')
      },
      {
        path: '/Xuefen',
        name: 'Xuefen',
        meta: {
          title: '学分证明'
        },
        component: () => import('@/views/xuefen.vue')
      },
      {
        path: '/Peixun',
        name: 'Peixun',
        meta: {
          title: '培训证明'
        },
        component: () => import('@/views/peixun.vue')
      },
      // {
      //   path: '/Weituo',
      //   name: 'Weituo',
      //   meta: {
      //     title: '我的委托书',
      //   },
      //   component: () => import('@/views/weituo.vue')
      // },
      {
        path: '/User',
        name: 'User',
        meta: {
          title: '个人信息'
        },
        component: () => import('@/views/user.vue')
      },
      {
        path: '/Solve',
        name: 'Solve',
        meta: {
          title: '线上答疑'
        },
        component: () => import('@/views/solve.vue')
      },
      {
        path: '/Action',
        name: 'Action',
        meta: {
          title: '操作手册'
        },
        component: () => import('@/views/action.vue')
      },
    ]
  },
  {
    path: '/Tixi',
    name: 'Tixi',
    meta: {
      title: '课程体系'
    },
    component: () => import('@/views/tixi.vue')
  },
  {
    path: '/Gouke',
    name: 'Gouke',
    meta: {
      title: '购课'
    },
    component: () => import('@/views/gouke.vue')
  },
  {
    path: '/Affirm',
    name: 'Affirm',
    meta: {
      title: '确认选择'
    },
    component: () => import('@/views/affirm.vue')
  },
  {
    path: '/BuyOk',
    name: 'BuyOk',
    meta: {
      title: '支付结果'
    },
    component: () => import('@/views/buyOk.vue')
  },
  {
    path: '/Video',
    name: 'Video',
    meta: {
      title: '视频播放'
    },
    component: () => import('@/views/video.vue')
  },
  {
    path: '/Gongxu_video',
    name: 'Gongxu_video',
    meta: {
      title: '视频播放'
    },
    component: () => import('@/views/gongxu_video.vue')
  },
  {
    path: '/News',
    name: 'News',
    meta: {
      title: '公告详情'
    },
    component: () => import('@/views/news.vue')
  },
  {
    path: '/ConsultList',
    name: 'ConsultList',
    meta: {
      title: '行业咨询'
    },
    component: () => import('@/views/consult/list.vue')
  },
  {
    path: '/ConsultDetail',
    name: 'ConsultDetail',
    meta: {
      title: '咨询详情'
    },
    component: () => import('@/views/consult/detail.vue')
  },
  {
    path: '/Teacher',
    name: 'Teacher',
    meta: {
      title: '咨询详情'
    },
    component: () => import('@/views/teacher.vue')
  },
  {
    path: '/IssueList',
    name: 'IssueList',
    meta: {
      title: '常见问题'
    },
    component: () => import('@/views/issue/list.vue')
  },
  {
    path: '/IssueInfo',
    name: 'IssueInfo',
    meta: {
      title: '常见问题'
    },
    component: () => import('@/views/issue/info.vue')
  },
  {
    path: '/xfList',
    name: 'xfList',
    meta: {
      title: '学分查询'
    },
    component: () => import('@/views/xf_list.vue')
  },
]
