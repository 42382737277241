<!--  -->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      
    };
  },
  computed: {},
  watch: {},
  created() {
    let explorer = window.navigator.userAgent
    if (explorer.indexOf("Chrome") == -1) {
      alert('推荐使用谷歌浏览器进行学习!')
    }
    if (document.documentMode) {
      window.document.getElementsByTagName('html')[0].style.fontSize = '1px' 
    } else {
      var arr = navigator.userAgent.split(' '); 
      var chromeVersion = '';
      for(var i=0;i < arr.length;i++){
        if(/chrome/i.test(arr[i])) {
          chromeVersion = arr[i]
        }
      }
      if(chromeVersion){
        let sion= Number(chromeVersion.split('/')[1].split('.')[0]);
        if (sion < 97) {
          window.document.getElementsByTagName('html')[0].style.fontSize = '1px' 
        }
      }
    }
  },
  mounted() {
    let URL = 'http://newgzm.xyyysp.com'
    if (window.screen.width < 500) {
      let a = document.createElement('a');
      a.href = URL;
      a.click();
    }
  },
  //方法集合
  methods: {

  },
}
</script>

<style lang='less' scoped>

</style>