import request from '../utils/request'

export default {
  getUserInfo: (data) => {
    return request({
      url: 'Home/Apinew/get_user',
      method: 'post',
      data: data
    })
  },
  uploadUserImg: (data) => {
    return request({
      url: 'Home/Apinew/user_txsc',
      method: 'post',
      data: data
    })
  },
  uploadEntrustBook: (data) => {
    return request({
      url: 'Home/Apinew/wts_upload',
      method: 'post',
      data: data
    })
  },
  getEntrustBook: (data) => {
    return request({
      url: 'Home/Apinew/wts_list',
      method: 'post',
      data: data
    })
  },
  getConsult: (data) => {
    return request({
      url: 'Home/Apinew/home_zixun',
      method: 'post',
      data: data
    })
  },
  getConsultBanner: (data) => {
    return request({
      url: 'Home/Apinew/home_pc_lbt',
      method: 'post',
      data: data
    })
  },
  getConsultMsg: (data) => {
    return request({
      url: 'Home/Apinew/home_zixun_info',
      method: 'post',
      data: data
    })
  },
  getYears: (data) => {
    return request({
      url: 'Home/Apinew/get_years',
      method: 'post',
      data: data
    })
  },
  openInvoice: (data) => {
    return request({
      url: 'Home/Apinew/fp_kaiju',
      method: 'post',
      data: data
    })
  },
  getInvoice: (data) => {
    return request({
      url: 'Home/Apinew/fp_info',
      method: 'post',
      data: data
    })
  },
  getCredit: (data) => {
    return request({
      url: 'Home/Apinew/get_xxzm',
      method: 'post',
      data: data
    })
  },
  getGxCredit: (data) => {
    return request({
      url: 'Home/Apigx/get_xxzm',
      method: 'post',
      data: data
    })
  },
  getTrainProve: (data) => {
    return request({
      url: 'Home/Apinew/hq_zs',
      method: 'post',
      data: data
    })
  },
  getFapiaoArr: (data) => {
    return request({
      url: 'Home/Apinew/fp_list',
      method: 'post',
      data: data
    })
  },
  getCode: (data) => {
    return request({
      url: 'Home/ApiRenew/get_msg_code',
      method: 'post',
      data: data
    })
  },
  upDateType: (data) => {
    return request({
      url: 'Home/ApiRenew/update_leixing',
      method: 'post',
      data: data
    })
  },
  getZyRoot: (data) => {
    return request({
      url: 'Home/ApiRenew/yz_zyk_zige',
      method: 'post',
      data: data
    })
  },
}
