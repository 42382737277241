/**
 * 封装 axios 请求模块
 */
import axios from "axios";
import qs from 'qs'
import Store from '@/store'

const request = axios.create({
  baseURL: "https://gzadmin.xyyysp.com/index.php/",
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});

// 请求拦截器
request.interceptors.request.use((cfg) => {
  cfg.data = cfg.data || {}
  let token = localStorage.getItem('token') || ''
  if (token) {
    cfg.data.token = token
  }
  let year = localStorage.getItem('year') || ''
  if (year && !cfg.data.year) {
    cfg.data.year = year
  }
  cfg.data = qs.stringify(cfg.data)
  return cfg;
});

// 响应拦截器
request.interceptors.response.use(
  res => {
    if (res.status === 200) {
      let data = res.data
      if (data.code === 414) {
        localStorage.setItem("userInfo", '')
        localStorage.setItem("token", '')
        localStorage.setItem("year", '')
        Store.commit('setToken', '')
        window.location.href = window.location.protocol + '//' + window.location.host
        return Promise.resolve(res)
      } else {
        return Promise.resolve(res)
      }
    } else {
      Promise.reject(res)
    }
  },
  err => {
    return Promise.reject(err)
  }
)

export default request;
