import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from './api/my'


import ElementUI from 'element-ui';
import './assets/css/public.less'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);



import Print from 'vue-print-nb'
Vue.use(Print);


Vue.prototype.setUserInfo = function () {
  Api.getUserInfo().then(res => {
    if (res.status == 200) {
      localStorage.setItem('userInfo', JSON.stringify(res.data))
    }
  })
}


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
